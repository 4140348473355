<template>
  <div class="stage">
    <Search :searchOptions="options" @search="getSearch">
      <div class="btns">
        <el-button class="btn" type="primary" @click="modalShow"
          >新增</el-button
        >
      </div>
    </Search>
    <div class="stage_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)">查看</el-button
            ><el-button type="text" @click="look('edit', scope)">编辑</el-button
            ><el-button type="text" style="color: red" @click="del(scope)"
              >删除</el-button
            >
          </div>
        </template>
      </Table>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      title="类型配置"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :disabled="mode == 'look' ? true : false"
      >
        <el-form-item label="类型名称" prop="typeName">
          <el-input v-model="ruleForm.typeName" placeholder="请输入类型名称" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      dialogVisible: false,
      mode: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "input",
          placeholder: "类型名称",
          prop: "shopName",
        },
      ],
      tableData: [],
      tableOptions: [
        {
          label: "类型名称",
          prop: "typeName",
        },
        {
          label: "创建时间",
          prop: "createTime",
        },
      ],
      ruleForm: {
        typeName: "",
      },
      rules: {
        typeName: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    look(type, row) {
      this.mode = type;
      this.ruleForm = Object.assign({}, row);
      this.dialogVisible = true;
    },
    del(row) {
      this.$confirm("此操作将删除该信息, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { flag } = await this.$del(
            `/xng/backed/lifeService/delLifeServiceType?id=${row.id}`
          );
          if (flag) {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSearch(val) {
      this.page = {
        pageNum: 1,
        pageSize: 10,
        ...val,
      };
      this.loadData();
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/lifeService/selectPageListTypeByName",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows;
      }
    },
    modalShow() {
      this.dialogVisible = true;
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.ruleForm = {};
          this.mode = "add";
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.ruleForm);
          if (this.ruleForm.id) {
            const { flag } = await this.$post(
              "/xng/backed/lifeService/updateLifeServiceType",
              this.ruleForm
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "编辑成功",
                type: "success",
              });
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            const { flag } = await this.$post(
              "/xng/backed/lifeService/addLifeServiceType",
              this.ruleForm
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
            } else {
              this.$message.error("新增失败");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.stage {
  width: 100%;
  .btns {
    padding: 0 10px;
  }
  .stage_content {
    width: 100%;
  }
}
</style>
